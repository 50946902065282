.contact-form{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80vh;
    margin-top: 4rem;
      /* scroll */
  padding-top: 3rem;
}

.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    
}

.c-right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;   
}

textarea{
    height: 4rem!important;
}

.c-blur1{
    top: 1rem;
    left: 8rem;
}
/* snd bttn */
.main
{
    position: relative;
    display: inline-block;
    padding: 12px 26px;
    color: #db8e27;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    font-size: 18px;
    overflow: hidden;
    transition: 0.2s;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
}
.main:hover
{
    color: #255784;
    background: #db8e27;
    box-shadow: 0 0 10px #db8e27, 0 0 40px #db8e27, 0 0 80px #db8e27;
    transition-delay: 1s;
}
.main span
{
    position: absolute;
    display: block;
}
.main span:nth-child(1)
{
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#db8e27);
}
.main:hover span:nth-child(1)
{
    left: 100%;
    transition: 1s;
} 
.main span:nth-child(2)
{
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#db8e27);
}
.main:hover span:nth-child(2)
{
    right: 100%;
    transition: 1s;
    transition-delay: 0.5s;
}
.main span:nth-child(3)
{
    top: -100℅;
    right: 0%;
    width: 2px%;
    height: 100%;
    background: linear-gradient(180deg,transparent,#2196f3);
}
.main:hover span:nth-child(3)
{
    top: 100%;
    transition: 1s;
    transition-delay: 0.25s;
}
.main span:nth-child(4)
{
    bottom: -100℅;
    left: 0%;
    width: 2px%;
    height: 100%;
    background: linear-gradient(360deg,transparent,#2196f3);
}
.main:hover span:nth-child(4)
{
    bottom: 100%;
    transition: 1s;
    transition-delay: 0.75s;
}
/* end snd bttn */

@media screen and (max-width: 480px) {
    .contact-form{
        padding: 0;
        flex-direction: column;
        gap: 5rem;
        height: auto;
    }
    .c-right .user{
        width: 16rem;
    }

}   