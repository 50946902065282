/* .footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  position: relative;
  margin-top: -7rem;
}
.f-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
  gap: 4rem;
  color: white;
}
.f-icons {
  display: flex;
  gap: 2rem;
} */
/* .f-icons:hover {
  transform: scale(1.5);  
} */
/* .insta:hover{
  color: red;
  background-color: aqua;
} */
.container-fluid{
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  position: relative;
  /* margin-top: -7rem; */
}
#footer-wrapper {
  /* position: absolute; */
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;

  /* margin-top: 4rem; */
  /* gap: 4rem; */
/*  */
  background: #db8e27;
  border-top-color: #393232;
  font-size: 0.9em;
  color: #fff;
  margin-top: 50px;
  /* padding: 60px 0 0; */
}

/* .footer-area {
  position: relative;
} */

.footer-area .footer-ribbon {
  position: absolute;
  bottom: 16.7rem;
  left: 2rem;
  /* margin: -76px 0 0 0px; */
  padding: 8px 20px;
  color: #fff;
  font-size: 1.3em;
  font-family: "Shadows Into Light", cursive;
  background: #1d1d1be3;
}
.footer-area .footer-ribbon::before {
    /* border-right: 10px solid #00334d; */
    border-top: 29px solid transparent;
    content: "";
    left: -17px;
    position: absolute;
    top: 0;
    width: 8px;
    border-right: 10px solid #4d4400;
    border-top: 29px solid transparent;
    content: "";
    left: -17px;
    position: absolute;
    top: 0;
    width: 8px;
}

.footer_area_cp {
  overflow: hidden;
  padding: 15px 0;
  margin-bottom: 0;
  border-top: 1px solid #fff;
  border-image: linear-gradient(to right, transparent, #dbdbdb, transparent);
  border-image-slice: 1;
}
/* ///footer */

.container{
  display: flex;
  justify-content: center;
}
.footer-top-area{
margin-top: 2rem;
}
ul.nab {
  
  /* float: right; */
  list-style: none;
  display: block;
}
.nav-item li a{
  padding: 0 8px;
}
.lststyl{
  list-style: none;
  display: inline-block;
}
.lststyl a{
  font-size: 0.9em;
  color: #fff;
}
.linpipe{
  margin: 0px 10px;
}
.address{
  padding: 0px;
  list-style: none;
  margin-bottom: 8px;
  list-style-type: none;
  text-align: center;
}

.social_area{
  display: flex;
  gap:1rem;
  /* margin-left: 3rem; */
  align-items: center;
  justify-content: center;
  list-style: none;
  padding-bottom: 2rem;
  
}

.social-icons-linkedin a,
.social-icons-twitter a,
.social-icons-facebook a {
  background: #fff ;
  border-radius: 50%;
  display: block;
  height: 38px;
  line-height: 39px;
  width: 40px;
  text-align: center;
  color: #333 ;
}
.blokwise{
  display: inline-block;
  margin-right: 3.5px
}
.social-icons-facebook a:hover{
  background-color: #003167;
  color: #fff !important;
}
.social-icons-twitter a:hover{
  background-color: #0088cc;
  color: #fff !important;
}
.social-icons-linkedin a:hover{
  background-color: #0e76a8;
  color: #fff !important;
}


@media screen and (max-width: 480px){
  /* .f-content{
    transform: scale(.5);
  } */
  .footer-area .footer-ribbon {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: absolute;
    left: 3rem;
    bottom: 16.8rem;

  }
  .footer-area .footer-ribbon::before {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
