.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  top: 0;
  
}
.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 2rem;
}

.n-name {
  font-size: 1.3rem;
  font-weight: bold;
}
.n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.n-list {
  flex: 10;
}
.n-list > ul {
  display: flex;
  gap: 1.5rem;
  margin-right: 4rem;
  text-transform: uppercase;
  /* padding: 5px 8px;
  transition: 0.4s ease; */
    /* border: 2px solid #ffed5a; */
    /* color: #000; */
    /* background-color: #9dffa1;
    border: 2px solid transparent; */
    -webkit-transition: .5s all ease-in;
    transition: .5s all ease-in;
}

.n-list > ul :hover, .n-list > ul :focus {
  /* color: var(--orange); */
  /* border: 2px solid pink; */
  
  /* box-shadow: 0px 0px 25px #fcc203, 0px 0px 25px #fcc203; */
  /* background-color: #fff; */
  background-color: var(--orange);
  cursor: pointer;
    -webkit-animation: breathing 3s infinite;
            animation: breathing 3s infinite;
    -webkit-box-shadow: 0px 0px 10px 0px #fb982f;;
            box-shadow: 0px 0px 10px 0px #fb982f;;
}
@-webkit-keyframes breathing {
  0% {-webkit-box-shadow: 0px 0px 10px 0px #fb982f;;box-shadow: 0px 0px 10px 0px #fb982f;;}
  50% {-webkit-box-shadow: 0px 0px 30px 20px #fb982f;;box-shadow: 0px 0px 30px 20px #fb982f;;}
  100% {-webkit-box-shadow: 0px 0px 10px 0px #fb982f;;box-shadow: 0px 0px 10px 0px #fb982f;;}
}

@keyframes breathing {
  0% {-webkit-box-shadow: 0px 0px 10px 0px #fb982f;;box-shadow: 0px 0px 10px 0px #fb982f;;}
  50% {-webkit-box-shadow: 0px 0px 12px 10px #fb982f;;box-shadow: 0px 0px 12px 10px #fb982f;;}
  100% {-webkit-box-shadow: 0px 0px 10px 0px #fb982f;;box-shadow: 0px 0px 10px 0px #fb982f;;}
}
/* gradient movable */
/* .gr-move {
  position: relative;
} */

  .gr-move .gradient-neon {
      background-image: -webkit-gradient(linear, left top, right top, from(#9C27B0) , color-stop(#E91E63), to(#8BC34A));
      background-image: linear-gradient(90deg, #9C27B0 , #E91E63, #8BC34A);
      background-size: 300%;
      border: none;
      color: #fff;
  }

  .gr-move:hover .gradient-neon {
      -webkit-animation:moveInnerBg 4s infinite;
              animation:moveInnerBg 4s infinite;
  }
   
  .gr-move:hover .gradient-neon{
      background-image: -webkit-gradient(linear, left top, right top, from(#ef92ff) , color-stop(#ff7ba8), to(#d0ff99));
      background-image: linear-gradient(90deg, #ef92ff , #ff7ba8, #d0ff99);
  }

  .gr-shadow2, .gr-shadow2::before, .gr-shadow2::after {
      content:'';
      position: absolute;
      top: 0px;
      border-radius: 4px;
      width: 139px;
      height: 79px;
      z-index: -1;
      -webkit-transition: all .3s ease-in;
      transition: all .3s ease-in;            
      opacity:0;
  }

  .gr-move:hover .gr-shadow2, .gr-shadow2::before, .gr-shadow2::after {
      opacity:1;
  }

  .gr-shadow2 {
      -webkit-box-shadow: 0px 0px 30px 20px #d4f39a;
              box-shadow: 0px 0px 30px 20px #d4f39a;
      left:45px;
  }

  .gr-shadow2::before {        
      right: 0;
      bottom: 0;
      -webkit-box-shadow: 0px 0px 30px 20px #fb84ff;
              box-shadow: 0px 0px 30px 20px #fb84ff;
      -webkit-animation:moveBg1 2.5s infinite;
              animation:moveBg1 2.5s infinite;
  }

  .gr-shadow2::after {        
      bottom: 0;
      -webkit-box-shadow: 0px 0px 30px 20px #eeaba3;
              box-shadow: 0px 0px 30px 20px #eeaba3;
      -webkit-animation:moveBg2 3s infinite;
              animation:moveBg2 3s infinite;
  }
/* Animation for Moving Gradient Background */
@-webkit-keyframes moveBg1 {
  0% {left :0px;}
  50% {left:-45px;}
  100% {left:0px;}
}

@keyframes moveBg1 {
  0% {left :0px;}
  50% {left:-45px;}
  100% {left:0px;}
}

@-webkit-keyframes moveBg2 {
  0% {left :0px;}
  50% {left:45px;}
  100% {left:0px;}
}

@keyframes moveBg2 {
  0% {left :0px;}
  50% {left:45px;}
  100% {left:0px;}
}


.n-button {
  flex: 2;
}

@media screen and (max-width: 480px) {
  .n-list {
    display: none;
  }
}
