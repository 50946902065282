.toggle{
    display: flex;
    justify-content:space-between;
    border: 3px solid var(--orange);
    border-radius: 1rem;
    position: relative;
    padding: 2px;
    cursor: pointer;

}
.toggle>*{
    width: 1rem;
    height: 1rem;
    color: var(--orange);
}
.t-button{
    border-radius: 100%;
    background: var(--orange);
    position: absolute;
}

@media screen and (max-width: 480px){
    .toggle{
        position: relative;
        left:6rem;
    }
}
