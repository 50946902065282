@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;1,600&display=swap'); 
:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --lorange : #db8e27;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}

/*
.button {
    font-family: poppins;
    width: 6rem;
    background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
    border-radius: 34px;
    border: 1px solid #fb982f;
    
    color:#fff;
    font-size: 16px;
    padding: 11px 14px 11px 14px;
    transition: 0.2s;
    line-height: 1;
    cursor: pointer;
  }
  .button:hover{
    background: #fff!important;
    border: 1px solid #fb982f;
    color: #fb982f;
  }
*/
  /* .button:hover{
    letter-spacing: 3px;
    color: rgb(24, 203, 235);
  } */
  
  /* .button:hover {
    box-shadow: 0 5px 50px 0 #fdc50f inset, 0 5px 50px 0 #fdc50f,
                0 5px 50px 0 #fdc50f inset, 0 5px 50px 0 #fdc50f;
    text-shadow: 0 0 5px #fff,0 0 5px #fff;
    /* background: white; */
    /* cursor: pointer; */
    /* border: 1px solid var(--orange);
    color: var(--orange); */
    /* line-height: 1; */
  /* } */

  .button{
    padding: 4px 33px;
    font-size: 14px;
    border: 1px solid #fb982f;
    color: #FFF;
    display: inline-block;
    font-weight: 700;
    position: relative;
    border-radius: 34px;
    transition: all .3s;
    overflow: hidden;
    z-index: 1;
    line-height: 1;
    cursor: pointer;
  }
  .button{

    text-decoration: none;
    outline: none;
}  
.button::after{
  content: '';
  width: 100%;
  height: 65px;
  top: 50%;
  left: 50%;
  background: #fb982f;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    z-index: -1;
    position: absolute;
    transition: all 0.5s ease;
    opacity: 1;
}
.button Link::after{
  height: 150px;
  opacity: 1;
}

.button:hover::after{
  height: 150px;
  opacity: 1;
}
.rdbutton_btn:hover,.button:focus {
  text-decoration: none;
  outline: none;
}

  @media screen and (max-width: 480px) {
    .App {
      padding: 0.5rem 1rem;

    }
    .mobile_view{
      display: flex;
      justify-content: center;
    }
    
    .mob_pos{
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    
  }

  

  