.Intro {
  display: flex;
  height: 77vh;
  margin-top: 6rem;
}
/* .shine_name{
  color: #3cffed;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #03a9f4, 0 0 40px #03a9f4, 0 0 50px #03a9f4, 0 0 60px #03a9f4, 0 0 70px #03a9f4;
} */

/* left side styling */
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.i-name > :nth-child(1) {
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
  animation: blink 2s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.i-name > :nth-child(2) {
  color: var(--lorange);
  font-weight: 700;
  font-size: 2.3rem;
  /* text-shadow: 2px 2px #664f2c; */
  text-transform: capitalize;
 
}

.i-name > :nth-child(3) {
  /* font-weight: 500; */
  font-size: .9rem;
  color: var(--gray) ;
  margin-top: 10px;
}
.i-button {
  /* width: 6rem; */
  height: 2.2rem;
}
.i-icons {
  margin-top: 3rem;
  display: flex;
  gap: 0rem;
}
.i-icons > * {
  transform: scale(0.5);
}
.i-icons > *:hover {
  cursor: pointer;
  
}
/* right side styling */
.i-right {
  flex: 1;
  left:15.5%;
  position: relative;
}
.i-right > * {
  position: absolute;
 
  z-index: 1;
}
.i-right > :nth-child(1) {
  transform: scale(0.69);
  left: -15%;
  top: -9%;
}
.i-right > :nth-child(2) {
  top: -4.6rem;
  transform: scale(0.67);
  left: -3rem;
}
.i-right > :nth-child(3) {
  transform: scale(1.4);
  left: 28%;
}
.i-right > :nth-child(4) {
  transform: scale(0.62);
  top: -19%;
  left: -24%;
  border-radius: 50%;
  padding: 0;
}

/* blur */
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

@media screen and (max-width: 480px) {
  .Intro {
    flex-direction: column;
    gap: 0rem;
    height: 45rem;
    margin-top: 3rem;
  }
  .i-name > :nth-child(1) {
    font-size: 2rem;
    text-align: center;
  }
  .i-name > :nth-child(2) {

    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
   
  }
  .i-name > :nth-child(3) {
    font-weight: 500;
    font-size: .8rem;
    text-align: center;

  }
  .i-icons {
    margin-top: 0rem;
   
  }
  .i-right {
    transform: scale(0.8);
    left: -3rem;
    width: 80%;
  }
  .i-right > :nth-child(1) {
    transform: scale(0.69);
    left: -7%;
    top: 0%;
}
.initial{
  left: -24%;
}
  .mob iframe{
    width: 85%;
  }
  .i-right .blur {
    display: none;
  }
  .floating-div:nth-of-type(1){
    /* display: none; */
    top: -7rem !important;;
  }
  .floating-div{
    left: 3rem !important;
    top: 18rem;
  }
  /* .floatingDiv{
    left: 3rem !important;
    top: 18rem;
  } */
}
